<template>
  <section class="main-wrapper">
    <slot name="header">
    
    </slot>
    <slot name="body">
    
    </slot>
    <slot name="system">
    
    </slot>
  </section>
</template>

<script>
export default {
  name: "MainWrapper",
  props: {
    data: {}
  },
}
</script>

<style scoped>

</style>